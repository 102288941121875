import { useContext, useEffect, useState } from "react";
import { JobRoleList, PublishedSchedule, ScheduleData, Staff, StyleDetails } from "../../helpers/workTimeScheduleHelper";
import dayjs from 'dayjs';
import Loader from "../loader/Loader";
import { useWindowSize } from "react-use";
import { MOBILE_VIEW_BREAKPOINT } from "../../constants/constants";
import SwapScheduleDesktopView from "./SwapScheduleDesktopView";
import noPublishedSchedule from '../../assets/images/no_published_schedule.svg';
import { JTranslation } from "../../helpers/jTranslate";
import SwapScheduleMobileView from "./SwapScheduleMobileView";
import SwapRequestFormSideBar from "./SwapRequestFormSideBar";
import { CommonCtx } from "../../context/CommonCtxProvider";
import { AxiosResponse } from "axios";
import { UseQueryResult } from "react-query";
import useQueryHook from "../../hooks/useQueryHook";
import { USER_ROLES } from "../../constants/queryKeys";
import { getUserRoles } from "../../helpers/userRolesHelper";

type Props = {
  staffsWithSchedule: ScheduleData | null;
  staffsWithScheduleQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
  scheduleDataQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
  selectedWeek: dayjs.Dayjs;
  parent?: string;
}

function SwapScheduleView({
  staffsWithSchedule,
  staffsWithScheduleQuery,
  scheduleDataQuery,
  selectedWeek,
  parent
}: Readonly<Props>) {
  const { width } = useWindowSize()
  const breakPoint = MOBILE_VIEW_BREAKPOINT
  const { showSideBar, setShowSideBar } = useContext(CommonCtx)

  const [selectedShift, setSelectedShift] = useState<PublishedSchedule | null>(null)
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null)
  const [rolesList, setRolesList] = useState<JobRoleList[] | []>(); // roles list

  const swapShiftRequest = (shift: PublishedSchedule, staff: Staff) => {
    setSelectedShift(shift)
    setSelectedStaff(staff)
    setShowSideBar(true)
  }

  const cleanData = () => {
    setSelectedShift(null)
    setSelectedStaff(null)
  }

  const getRoleColorCode = (roleId: string): StyleDetails => {
      const role = rolesList?.find(role => role.id === roleId)

      if (role?.styleDetails) {
          return role.styleDetails
      } else {
          return {
              backgroundColor: '#87b6fb',
              color: '#262930'
          }
      }
  }
  
  // roles list
  const userRolesQuery = useQueryHook(USER_ROLES, getUserRoles, (res) => setRolesList(res.data?.data.lists));

  useEffect(() => {
    if (!showSideBar) {
      cleanData()
    }
  }, [showSideBar])

  // loading screen
  if (staffsWithScheduleQuery.isFetching || scheduleDataQuery.isFetching) {
    return <Loader />
  }

  // If no published schedule
  if (!staffsWithSchedule) {
    return (
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <div className="flex-grow-1 align-items-center justify-content-center p-3 text-center mt-5">
          <img src={noPublishedSchedule} alt='no published schedule' style={{ textAlign: 'center' }} />
          <h5 className="mt-2 mb-3">
            <JTranslation typeCase="none" text={"No published schedule for the current week"} />
          </h5>
        </div>
      </div>
    )
  }

  return <>
    <SwapRequestFormSideBar
      handleClose={() => {
        cleanData()
        setShowSideBar(false)
      }}
      shiftData={selectedShift}
      staffData={selectedStaff}
      staffsWithSchedule={staffsWithSchedule}
    />
    {width >= breakPoint ? (
      <SwapScheduleDesktopView
        selectedWeek={selectedWeek}
        staffsWithSchedule={staffsWithSchedule}
        isLoading={staffsWithScheduleQuery.isRefetching || scheduleDataQuery.isRefetching || userRolesQuery.isFetching}
        swapShiftRequest={swapShiftRequest}
        parent={parent}
        getRoleColorCode={getRoleColorCode}
      />
    ) : (
      <SwapScheduleMobileView
        selectedWeek={selectedWeek}
        staffsWithSchedule={staffsWithSchedule}
        isLoading={staffsWithScheduleQuery.isRefetching || scheduleDataQuery.isRefetching || userRolesQuery.isFetching}
        swapShiftRequest={swapShiftRequest}
        parent={parent}
        getRoleColorCode={getRoleColorCode}
      />
    )}
  </>;
}

export default SwapScheduleView;
