import { useEffect, useState } from 'react'
import { ADD, DURATION, RESET, UPDATE } from '../../constants/strings'
import { addMandatoryDay, deleteMandatoryDay, getAllMandatoryDays, initialValuesForConfirmPopup, initialValuesMandatoryDay, mandatoryFullDayValidation, mandatoryHalfDayValidation, updateMandatoryDay } from '../../helpers/availabilityHelper'
import { AddUpdateMandatoryResponse, ConfirmPopupType, MandatoryDay, MandatoryFormData, ToastMessageProps } from '../../constants/staticTypes'
import { AlertVariant, DATE_FORMAT, PageMode, PermissionKeys, SubPermissionKeys } from '../../constants/constants'
import { AxiosResponse } from 'axios'
import { checkPermission, dateTimeFormatter, getCurrentDateByTimeZone } from '../../helpers/utils'
import { Column } from 'primereact/column'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { DataTable } from 'primereact/datatable'
import { FaEdit } from 'react-icons/fa'
import { Field, Form, ErrorMessage, Formik, FieldProps } from 'formik'
import { MANDATORY_DAYS_LIST } from '../../constants/queryKeys'
import { SelectButton } from 'primereact/selectbutton'
import { useQueryClient } from 'react-query'
import DatePickerComponent from '../date_picker/DatePickerComponent'
import dayjs from 'dayjs'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import MlxTooltip from '../common/MlxTooltip'

type Props = {
	onClose: Function
	pageMode: PageMode.ADD | PageMode.EDIT
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
}

function MandatoryDaysForm({ onClose, pageMode, setToastMessage, setMode }: Props) {
	const queryClient = useQueryClient()
	const mutation = useMutationHook(queryClient, true)
	const submitButtonName = pageMode === PageMode.EDIT ? UPDATE : ADD
	const options = ['morning', 'afternoon'];
	// CONTEXT VARIABLE
	const [mandatoryData, setMandatoryData] = useState<MandatoryFormData>(initialValuesMandatoryDay)
	const [mandatoryDataList, setMandatoryDataList] = useState<MandatoryDay[] | []>([])
	// STATE VARIABLES
	const [requestInfo, setRequestInfo] = useState<ConfirmPopupType>(initialValuesForConfirmPopup)
	const [rowId, setRowId] = useState("")

	// Get the current date
	const currentDate = getCurrentDateByTimeZone()
	// Calculate the maximum date, 5 years from now
	const maxDate = getCurrentDateByTimeZone()
	maxDate.setFullYear(currentDate.getFullYear() + 1)

	useEffect(() => {
		return () => {
			setMandatoryData(initialValuesMandatoryDay)
			setMode(PageMode.ADD)
		}
	}, []) // eslint-disable-line


	const onGetListSuccess = (res: AxiosResponse<any, any>): void => {
		const mandatoryDayList: MandatoryDay[] = res.data.data.lists
		setMandatoryDataList(mandatoryDayList)
	}

	// fetch all mandatory days
	const listData = useQueryHook(MANDATORY_DAYS_LIST, () => getAllMandatoryDays(), onGetListSuccess)

	const getValidationSchema = () => {
		if (mandatoryData.durationType === "full-day") {
			return mandatoryFullDayValidation
		} else {
			return mandatoryHalfDayValidation
		}
	}

	const validate = (values: MandatoryFormData) => {
		setMandatoryData((prevValues: MandatoryFormData) => {
			return {
				...prevValues,
				startDate: values.startDate,
				endDate: values.durationType === "full-day" ? values.endDate : values.startDate,
				durationType: values.durationType,
				timeLabel: values.timeLabel,
			}
		})
	}

	const handleSubmit = (values: MandatoryFormData) => {
		const { durationType, endDate, startDate, timeLabel } = values;
		const formattedValues: MandatoryFormData = {
			startDate: dayjs(startDate).format('YYYY-MM-DD'),
			endDate: dayjs(endDate).format('YYYY-MM-DD'),
			durationType,
			timeLabel,
			// ...(durationType === 'full-day'
			// 	? {}
			// 	: {
			// 		startTime: timeLabel === 'morning' ? '00:00' : '12:00',
			// 		endTime: timeLabel === 'morning' ? '11:59' : '21:59',
			// 		timeLabel,
			// 	}),
			...(pageMode === PageMode.EDIT
				? { id: mandatoryData.id }
				: {}
			),
		};

		if (pageMode === PageMode.ADD) {
			addMandatoryDay(mutation, formattedValues, onSuccess, onError)
		} else {
			updateMandatoryDay(mutation, formattedValues, onSuccess, onError)
		}
	};


	const onSuccess = (res: AddUpdateMandatoryResponse) => {
		listData.refetch()
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const effectiveDates = (rowData: MandatoryDay) => {
		const { durationType, endDate, startDate } = rowData
		if (durationType === "full-day") return <span>{dateTimeFormatter({ dateTime: startDate, format: DATE_FORMAT })} - {dateTimeFormatter({ dateTime: endDate, format: DATE_FORMAT })}</span>
		if (durationType === "half-day") return <span>{dateTimeFormatter({ dateTime: startDate, format: DATE_FORMAT })}</span>
	}

	const deleteButtonTemplate = (rowData: MandatoryDay) => {
		return <>
			{<i id={rowData.id} className={"ri-delete-bin-line delete " +
				(!checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.DELETE) &&
					" disabled")} data-testid={"delete#" + rowData.id}
				onClick={(e) => {
					e.stopPropagation();
					if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.DELETE)) {
						setRowId(rowData.id)
						setRequestInfo((prev) => {
							return {
								...prev,
								buttonName: String(rowData.id),
								visible: true,
							}
						})
					}
				}}></i>}
		</>
	}

	const editButtonTemplate = (rowData: MandatoryDay) => {
		return (
			<>
				{
					<MlxTooltip target='.edit'>
						<FaEdit
							className={`edit ${!checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.EDIT) && ' disabled'}`}
							data-pr-tooltip="Edit"
							data-pr-placement="bottom"
							onClick={(e) => {
								e.preventDefault()
								if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.EDIT)) {
									setMode(PageMode.EDIT)
									setMandatoryData(rowData)
								}
							}}
						/>
					</MlxTooltip>
				}
			</>
		)

	}


	return (
		<>
			{/* accept request popup */}
			<ConfirmPopup
				target={document.getElementById(requestInfo.buttonName) ?? undefined}
				visible={requestInfo.visible}
				onHide={() =>
					setRequestInfo((prev) => {
						return {
							...prev,
							visible: false,
						}
					})
				}
				message="Are you sure you want to proceed?"
				icon="pi pi-exclamation-triangle"
				accept={() => {
					//  api call
					deleteMandatoryDay(mutation, { id: rowId }, onSuccess, onError)
				}}
				data-testid="confirm-popup"
			/>

			<Formik
				enableReinitialize={true}
				validateOnChange={true}
				initialValues={mandatoryData}
				validate={validate}
				onSubmit={handleSubmit}
				validationSchema={getValidationSchema}
			>
				{(
					{ errors, touched }
				) => ( // nosonar
					<Form>
						<div className="row">
							<div className="mb-3 col-md-6">
								<div className="help-small">
									<label className="form-label d-flex">
										Effective dates - From
										<span className="mandatory ">*</span>
									</label>
								</div>
								<Field name="startDate">
									{({ field, form }: FieldProps) => (
										<DatePickerComponent
											isClearable={false}
											disableYear={pageMode === PageMode.EDIT}
											selectedDate={new Date(field.value)}
											minDate={currentDate}
											maxDate={maxDate}
											numberOfUpcomingYears={1}
											customInput={
												<input
													style={
														errors.startDate && touched.startDate
															? { border: '1px solid red' }
															: undefined
													}
													className="form-control"
													type="text"
													maxLength={10}
													autoComplete="off"
												/>
											}
											setDate={(value) => form.setFieldValue(field.name, value)}
										/>
									)}
								</Field>
								<ErrorMessage className="formik-error" name="startDate" component="div" />
							</div>

							{mandatoryData.durationType === "full-day" && (
								<div className="mb-3 col-md-6">
									<div className="help-small">
										<label className="form-label d-flex">
											To
											<span className="mandatory ">*</span>
										</label>
									</div>
									<Field name="endDate">
										{({ field, form }: FieldProps) => (
											<DatePickerComponent
												isClearable={false}
												disableYear={pageMode === PageMode.EDIT}
												selectedDate={new Date(field.value)}
												minDate={currentDate}
												maxDate={maxDate}
												numberOfUpcomingYears={1}
												customInput={
													<input
														style={
															errors.endDate && touched.endDate
																? { border: '1px solid red' }
																: undefined
														}
														className="form-control"
														type="text"
														maxLength={10}
														autoComplete="off"
													/>
												}
												setDate={(value) => form.setFieldValue(field.name, value)}
											/>
										)}
									</Field>
									<ErrorMessage className="formik-error" name="endDate" component="div" />
								</div>
							)}
						</div>
						<div className="mb-3">
							<label className="form-label d-flex">
								{DURATION}
								<span className="mandatory ">*</span>
							</label>
							<Field
								name="durationType"
								style={errors.durationType && touched.durationType ? { border: '1px solid red' } : undefined}
								as="select"
								className="form-control"
								type="text"
								value={mandatoryData.durationType}
								autoComplete="off"
								maxLength="250"
							>
								{/* Dropdown options */}
								<option key="full-day" value="full-day">
									Full Day
								</option>
								<option key="half-day" value="half-day">
									Half Day
								</option>
							</Field>
							<ErrorMessage className="formik-error" name="durationType" component="div" />
						</div>

						{mandatoryData.durationType === "half-day" && (
							<div className="mb-3">
								<Field name="timeLabel">
									{({ field, form }: FieldProps) => (
										<SelectButton
											value={field.value}
											style={errors.timeLabel && touched.timeLabel ? { border: '1px solid red' } : undefined}
											options={options}
											onChange={(e) => form.setFieldValue(field.name, e.value)}
										/>
									)}
								</Field>
								<ErrorMessage className="formik-error" name="timeLabel" component="div" />
							</div>
						)}

						{/* action button */}
						<div className="save-btn-section shadow save-btn-absolute">
							<button className="btn btn-custom-primary-outline" type="reset" onClick={() => onClose()}>
								{RESET}
							</button>

							<button className="btn btn-custom-primary" type="submit">
								{submitButtonName}
							</button>
						</div>
					</Form>
				)}
			</Formik>

			<hr />

			<div className="row h-100">
				<div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
					<DataTable
						value={mandatoryDataList}
						// paginator
						className="p-datatable-availability mandatory-days-table"
						rows={10}
						scrollable
						loading={listData.isFetching}
						scrollHeight="flex"
						// paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						// rowsPerPageOptions={[10, 25, 50]}
						dataKey="id"
						rowHover
						responsiveLayout="scroll"
						emptyMessage="No Mandatory Dates Added"
					// currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
					>
						<Column
							field="startDate"
							header="Effective Dates"
							className="word-break-grid"
							body={effectiveDates}
						/>
						<Column
							field="durationType"
							header="Time"
							className="word-break-grid"
							body={''}
						/>
						<Column
							field=""
							header=""
							className="word-break-grid"
							body={''}
						/>
						<Column field="edit" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={editButtonTemplate} alignFrozen="right" frozen={true} />
						<Column field="delete" style={{ maxWidth: '3rem', minWidth: '3rem' }} bodyClassName="bg-sticky-color" body={deleteButtonTemplate} alignFrozen="right" frozen={true} />
					</DataTable>
				</div>
			</div>

		</>
	)
}

export default MandatoryDaysForm