import { Skeleton } from 'primereact/skeleton'

const ShiftAssignmentSkeleton = () => {
    return (
        <div>
            <Skeleton height="10rem" className="mb-2"></Skeleton>
            <Skeleton height="20rem" className="mb-2"></Skeleton>
            <Skeleton height="10rem" className="mb-2"></Skeleton>
        </div>
    )
}

export default ShiftAssignmentSkeleton