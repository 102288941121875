import {
	AvailabilityData,
	AvailabilityFormData,
	AvailabilityWeekFormData,
	DatatablePagEvent,
	PaginationApi,
	ToastMessageProps,
} from '../../constants/staticTypes'
import { AlertVariant, MOBILE_VIEW_BREAKPOINT, PageMode, PermissionKeys, SubPermissionKeys } from '../../constants/constants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { checkPermission, convertUTCtoLocalByOffset, getAllWeekDays, toPascalCase } from '../../helpers/utils'
import dayjs from 'dayjs'
import { Badge } from 'primereact/badge'
import { FaEdit } from 'react-icons/fa'
import { useContext, useEffect, useState } from 'react'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { QueryClient } from 'react-query'
import { APPROVE, DELETE, EDIT, NOTE, REJECT } from '../../constants/strings'
import { ConfirmPopup } from 'primereact/confirmpopup'
import {
	AvailabilityConfirmPopupType,
	AvailabilityRequestStatus,
	initialValuesForAvailability,
	initialValuesForConfirmPopup,
	updateAvailabilityStatus,
} from '../../helpers/availabilityHelper'
import CommonModal from '../common_modal/CommonModal'
import useMutationHook from '../../hooks/useMutationHook'
import { AVAILABILITY_REQUESTS_LIST } from '../../constants/queryKeys'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useWindowSize } from 'react-use'
import { DataView } from 'primereact/dataview'
import { Card } from 'primereact/card'
import MlxTooltip from '../common/MlxTooltip'

type Props = {
	tableData: AvailabilityData[]
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
	showSideBar: boolean
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
	onAvailabilityDeleteClick: (data: AvailabilityData) => void
	queryClient: QueryClient
	setAvailabilityType: React.Dispatch<React.SetStateAction<'REGULAR' | 'TEMPORARY'>>
	dataTableEvent: DatatablePagEvent,
	setDataTableEvent: React.Dispatch<React.SetStateAction<DatatablePagEvent>>,
	paginationData: PaginationApi,
	loading: boolean,
}

function AllAvailabilityDataGrid({
	tableData,
	setToastMessage,
	setMode,
	setShowSideBar,
	showSideBar,
	onAvailabilityDeleteClick,
	queryClient,
	setAvailabilityType,
	dataTableEvent,
	setDataTableEvent,
	paginationData,
	loading
}: Readonly<Props>) {
	const availabilityMutation = useMutationHook(queryClient, true)

	// CONTEXT VARIABLES
	const { setAvailabilityData } = useContext(ScheduleManagementCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	const [rejectReason, setRejectReason] = useState('')
	const [availability, setAvailability] = useState<AvailabilityConfirmPopupType>(initialValuesForConfirmPopup)
	const [showWarning, setShowWarning] = useState(false)
	const [translationText, setTranslatedText] = useState<{
		edit: string,
		delete: string,
		search: string,
		paginationTemplate: string,
		emptyMessage: string,
		beyond: string,
	}>({ 
		edit: EDIT,
		delete: DELETE,
		search: 'Search',
		paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
		emptyMessage: 'No Availability found',
		beyond: ' and Beyond',
	});

	const { width } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'No Availability found', typeCase: 'pascal', translationContext }),					
					jTranslationText({ text: ' and Beyond', typeCase: 'lower', translationContext }),					
				])
				
				setTranslatedText({
					edit: translations[0] ?? EDIT,
					delete: translations[1] ?? DELETE,
					search: translations[2] ?? 'Search',
					paginationTemplate:
						translations[3]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: translations[4] ?? 'No Availability found',
					beyond: translations[5] ?? ' and Beyond',
				})
			} catch {
				setTranslatedText({
					edit: EDIT,
					delete: DELETE,
					search: 'Search',
					paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: 'No Availability found',
					beyond: ' and Beyond',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	useEffect(() => {
		if (!showSideBar) {
			setAvailability(initialValuesForConfirmPopup)
			setAvailabilityData(initialValuesForAvailability)
			setMode(PageMode.ADD)
		}
	}, [showSideBar]) // eslint-disable-line

	const approveAction = () => {
		switch (availability.action) {
			case 'APPROVED':
				changeRequestStatus('APPROVED')
				break
			case 'REJECTED':
				changeRequestStatus('REJECTED')
				break
			default:
				changeRequestStatus('REJECTED')
		}
	}

	const rejectRequestModalContent = (
		<>
			<div><JTranslation typeCase="capitalize" text={`Do you wish to proceed with rejecting the availability request?`} /></div>
			<>
				<p className="mt-3 mb-0">
					<JTranslation typeCase="pascal" text={NOTE} />
					{/* <span className="mandatory ">*</span> */}
				</p>
				<textarea
					name="reject-reason"
					className="form-control reason"
					value={rejectReason}
					onChange={(e) => setRejectReason(e.target.value)}
				/>
			</>
		</>
	)

	const closeRejectionModal = () => {
		setShowWarning(false)
		setRejectReason('')
	}

	const onSuccess = (res: any) => {
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
		queryClient.refetchQueries([AVAILABILITY_REQUESTS_LIST])
		closeRejectionModal()
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const changeRequestStatus = (status: AvailabilityConfirmPopupType['action']) => {
		const params: AvailabilityRequestStatus = { id: availability.requestId, newStatus: status, note: rejectReason }
		updateAvailabilityStatus(availabilityMutation, params, onSuccess, onError)
		closeRejectionModal()
	}

	const availabilityDateTemplate = (data: AvailabilityData) => {
		const startDate = convertUTCtoLocalByOffset(dayjs(data.startDate))
		const weekDays = getAllWeekDays(dayjs(data.startDate), 'MM/DD/YYYY')

		if (!data.isRecurring) {
			let endDate = weekDays.length > 0 ? ' - ' + convertUTCtoLocalByOffset(weekDays[weekDays.length - 1]) : ''
			return <span className=" ">{`${startDate} ${endDate}`}</span>
		}
		return <span className=" ">{`${startDate} ${translationText.beyond}`}</span>		
	}

	const availabilityStatusTemplate = (data: AvailabilityData) => {
		const { id, actionStatus } = data

		switch (actionStatus) {
			case 'APPROVED':
				return <Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={'Approved'} />} severity="success"></Badge>
			case 'REJECTED':
				return <Badge className="me-2 custom-badge" value={<JTranslation typeCase="pascal" text={'Rejected'} />} severity="danger"></Badge>
			default:
				return (
					<>
						<button
							data-test-id={`availability-approve-${data.id}-btn`}
							className={`btn  btn-sm btn-success me-2 ${
								!checkPermission(
									queryClient,
									PermissionKeys.MANAGE_AVAILABILITY,
									SubPermissionKeys.MANAGE_REQUEST
								) && ' disabled'
							} ${width <= breakPoint && 'rounded-circle'}`}
							id={id}
							type="button"
							onClick={(e) => {
								e.stopPropagation()
								if (
									checkPermission(
										queryClient,
										PermissionKeys.MANAGE_AVAILABILITY,
										SubPermissionKeys.MANAGE_REQUEST
									)
								) {
									setAvailability({
										buttonName: id as string,
										visible: true,
										requestId: id as string,
										action: 'APPROVED',
									})
								}
							}}
						>
							<i className={`ri-check-fill ${width > breakPoint && 'me-1'}`}></i>
							{width > breakPoint && <JTranslation typeCase="pascal" text={APPROVE} />}
						</button>
						<button
							data-test-id={`availability-reject-${data.id}-btn`}
							className={`btn btn-danger btn-sm me-2  ${
								!checkPermission(
									queryClient,
									PermissionKeys.MANAGE_AVAILABILITY,
									SubPermissionKeys.MANAGE_REQUEST
								) && ' disabled'
							} ${width <= breakPoint && 'rounded-circle'}`}
							type="button"
							onClick={(e) => {
								e.stopPropagation()
								if (
									checkPermission(
										queryClient,
										PermissionKeys.MANAGE_AVAILABILITY,
										SubPermissionKeys.MANAGE_REQUEST
									)
								) {
									setAvailability({
										buttonName: id as string,
										visible: false,
										requestId: id as string,
										action: 'REJECTED',
									})
									setShowWarning(true)
								}
							}}
						>
							<i className={`ri-close-fill ${width > breakPoint && 'me-1'}`}></i>
							{width > breakPoint && <JTranslation typeCase="pascal" text={REJECT} />}
						</button>
					</>
				)
		}
	}

	const availabilityEditTemplate = (data: AvailabilityData) => {
		if (['REJECTED', 'APPROVED'].includes(data.actionStatus)) {
			return <></>
		}
		return (
			<span className={`${width <= breakPoint ? 'rounded-background shadow-sm ms-2' : ''}`}>
				<MlxTooltip target='.edit'>
					<FaEdit
						data-test-id={`availability-edit-${data.id}-btn`}
						className={`edit ${
							!checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.EDIT) &&
							'disabled'
						}`}
						data-pr-position="bottom"
						data-pr-tooltip={translationText.edit}
						onClick={(e) => {
							e.preventDefault()
							if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.EDIT)) {
								const weekDays = getAllWeekDays(dayjs(data.startDate))
								const editableData: AvailabilityFormData = {
									id: data.id,
									isRecurring: data.isRecurring ? 'recurring' : 'temporary',
									startDate: dayjs(data.startDate),
									endDate: data.endDate ? dayjs(data.endDate) : null,
									staffId: data.staffId,
									weekdays: data.weekdays
										? data.weekdays.map((day, index) => {
												const weekDay: AvailabilityWeekFormData = {
													isAllDay: day.isAllDay,
													isAvailable: day.isAvailable,
													weekday: weekDays[index],
													notes: day.notes ?? '',
													availableTimes: day.availableTimes
														? day.availableTimes.map((time) => ({
																from: dayjs(
																	`${weekDays[index].format('MM/DD/YYYY')} ${time.from}`
																),
																to: dayjs(`${weekDays[index].format('MM/DD/YYYY')} ${time.to}`),
														}))
														: [],
												}
												return weekDay
										})
										: [],
								}
								setAvailabilityType(data.isRecurring ? 'REGULAR' : 'TEMPORARY')
								setAvailabilityData(editableData)
								setMode(PageMode.EDIT)
								setShowSideBar(true)
							}
						}}
					/>
				</MlxTooltip>
			</span>
		)
	}

	const availabilityDeleteTemplate = (data: AvailabilityData) => {
		if (['REJECTED', 'APPROVED'].includes(data.actionStatus)) {
			return <></>
		}
		return (
			<MlxTooltip target='.delete'>
				<i
					data-pr-tooltip={translationText.delete}
					data-pr-position='bottom'
					data-test-id={`availability-delete-${data.id}-btn`}
					className={`ri-delete-bin-line delete ${
						!checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.DELETE) &&
						'disabled'
					} ${width <= breakPoint ? ' rounded-background shadow-sm ms-2' : ''}`}
					onClick={(e) => {
						e.stopPropagation()
						if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY, SubPermissionKeys.DELETE)) {
							onAvailabilityDeleteClick(data)
						}
					}}
				></i>
			</MlxTooltip>
		)
	}

	const itemTemplate = (availabilityData: AvailabilityData) => {
        return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="m-0">{toPascalCase(availabilityData.staff?.preferredName ?? '')}</p>
                    <Badge severity={availabilityData.isRecurring ? 'success' : 'warning'} value={<>{availabilityData.isRecurring ? 'Regular' : 'Temporary'}</>} className="ms-2 custom-badge" />
                </div>
				<div className='mt-2'>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={'Effective Dates'} />:</span>
					    <p className="m-0 font-bold">{availabilityDateTemplate(availabilityData)}</p>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <span className="me-2"><JTranslation typeCase="capitalize" text={'Submitted Date'} />:</span>
                        <p className="m-0 font-bold">{convertUTCtoLocalByOffset(availabilityData.createdAt)}</p>
                    </div>                    
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-between align-items-center">
					<div className='d-flex justify-content-even align-items-center'>
                        {availabilityEditTemplate(availabilityData)}{availabilityDeleteTemplate(availabilityData)}
                    </div>
					<div className='d-flex justify-content-even align-items-center'>{availabilityStatusTemplate(availabilityData)}</div>
				</div>
			</Card>
		)
    }

	return (
		<div className="row mb-5 h-100">
			{/* accept request popup */}
			<ConfirmPopup
				target={document.getElementById(availability.buttonName) ?? undefined}
				visible={availability.visible}
				onHide={() =>
					setAvailability((prev) => {
						return {
							...prev,
							visible: false,
						}
					})
				}
				message={<JTranslation typeCase="pascal" text={"Are you sure you want to proceed?"} />}
				icon="pi pi-exclamation-triangle"
				accept={() => changeRequestStatus('APPROVED')}
			/>

			{/* reject request popup */}
			<CommonModal
				show={showWarning}
				modalContent={rejectRequestModalContent}
				title={'Reject Request'}
				modalSize="md"
				// disableProceed={!rejectReason && availability.action !== 'DELETE'}
				onHide={closeRejectionModal}
				callback={approveAction}
				hideCloseButton
			/>

			<div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
				{width <= breakPoint ? (
					<DataView
						value={tableData}
						layout={'list'}
						itemTemplate={itemTemplate}
						paginator
						paginatorClassName="p-dataview-customers"
						dataKey="id"
						emptyMessage={translationText.emptyMessage}

						lazy
						rowsPerPageOptions={dataTableEvent.rowsPerPageOptions}
						rows={dataTableEvent.rows}
						totalRecords={paginationData.total}
						first={dataTableEvent.first}
						onPage={(event) => setDataTableEvent({ ...dataTableEvent, ...event, forceUpdate: dataTableEvent.forceUpdate + 1 })}
						loading={loading}
					/>
					) : (
						<DataTable
							value={tableData}
							paginator
							className="p-datatable-customers"
							scrollable
							scrollHeight="flex"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
							dataKey="id"
							rowHover
							responsiveLayout="scroll"
							emptyMessage={translationText.emptyMessage}
							currentPageReportTemplate={translationText.paginationTemplate}

							lazy
							rowsPerPageOptions={dataTableEvent.rowsPerPageOptions}
							rows={dataTableEvent.rows}
							totalRecords={paginationData.total}
							first={dataTableEvent.first}
							onPage={(event) => setDataTableEvent({ ...dataTableEvent, ...event, forceUpdate: dataTableEvent.forceUpdate + 1 })}
							loading={loading}
						>
							<Column
								style={{ minWidth: '10rem' }}
								field="name"
								header={<JTranslation typeCase="pascal" text={'Name'} />}
								className="word-break-grid"
								body={(data: AvailabilityData) => {
									return <>{toPascalCase(data.staff?.preferredName ?? '')}</>
								}}
							/>
							<Column
								style={{ minWidth: '8rem'}}
								field="type"
								header={<JTranslation typeCase="pascal" text={'Type'} />}
								className="word-break-grid"
								body={(data: AvailabilityData) => {
									return <>{data.isRecurring ? 'Regular' : 'Temporary'}</>
								}}
							/>
							<Column
								style={{ minWidth: '8rem'}}
								field="date"
								header={<JTranslation typeCase="pascal" text={'Effective Dates'} />}
								className="word-break-grid"
								body={availabilityDateTemplate}
							/>
							<Column
								style={{ minWidth: '8rem' }}
								field="createdAt"
								header={<JTranslation typeCase="pascal" text={'Submitted Date'} />}
								className="word-break-grid"
								body={(data: AvailabilityData) => <>{convertUTCtoLocalByOffset(dayjs(data.createdAt))}</>}
							/>
							<Column
								style={{ minWidth:'13rem' }}
								field="status"
								header=""
								className="word-break-grid"
								body={availabilityStatusTemplate}
							/>
							<Column
								field="edit"
								style={{ maxWidth: '3rem', width: '3rem' }}
								bodyClassName="bg-sticky-color"
								body={availabilityEditTemplate}
								alignFrozen="right"
								frozen={true}
							/>
							<Column
								field="delete"
								style={{ maxWidth: '3rem', width: '3rem' }}
								bodyClassName="bg-sticky-color"
								body={availabilityDeleteTemplate}
								alignFrozen="right"
								frozen={true}
							/>
						</DataTable>
					)
				}
			</div>
		</div>
	)
}

export default AllAvailabilityDataGrid
