import { BD, CLOSE, SELECT_SHIFT, SHIFT } from '../../constants/strings'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { ErrorMessage, Field, FieldArrayRenderProps, FieldProps, FormikErrors, FormikTouched } from 'formik'
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch'
import { PrimeReactDropDown, ShiftAssignment, ShiftDropDown } from '../../constants/staticTypes'
import { AddShiftButton } from '../../helpers/workTimeScheduleHelper'
import dayjs from 'dayjs'
import { SetStateAction } from 'react'
import MlxTimePicker from '../mlx_time_picker/MlxTimePicker'

type Props = {
    shifts: PrimeReactDropDown[]
    arrayHelpers: FieldArrayRenderProps
    availabilityIndex: number
    roleIndex: number
    shiftIndex: number
    errors: FormikErrors<ShiftAssignment>
    touched: FormikTouched<ShiftAssignment>
    setShifts: (value: SetStateAction<ShiftDropDown[]>) => void
}

const ShiftAssignmentCard = ({
    shifts,
    availabilityIndex,
    roleIndex,
    shiftIndex,
    errors,
    touched,
    arrayHelpers,
    setShifts,
}: Props) => {   

    return (
        <>
            <div className="d-flex gap-2 align-items-center mb-2 flex-wrap">
                <div className="flex-grow-1">
                    <strong>{SHIFT}</strong>

                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}`}>
                        {({ field, form }: FieldProps) => {
                        const { code, name, startTime, endTime } = field.value;

                        //@ts-ignore
                        const rolesExist = errors?.availability?.[availabilityIndex]?.roles?.[roleIndex]?.shifts?.[shiftIndex]
                        const touchedExist = touched?.availability?.[availabilityIndex]?.roles?.[roleIndex]?.shifts?.[shiftIndex]

                        // Check if rolesExist and touchedExist are not undefined
                        const isError = rolesExist && touchedExist

                        // Apply style conditionally
                        const shiftStyle = isError ? { border: '1px solid red' } : undefined

                        const handleTimeChange = (name: string, time: any, key: 'startTime' | 'endTime') => {
                        
                            // Update the field value, ensuring it matches the updated "Custom Shift"
                            form.setFieldValue(field.name, {
                                ...field.value, // Retain the existing properties
                                [key]: time ? time.format('HH:mm:ss') : null, // Update startTime or endTime
                            });
                        };
                        
                        // Dropdown change handler
                        const handleDropdownChange = (e: DropdownChangeEvent) => {
                            const selectedShift = e.value;
                            form.setFieldValue(field.name, selectedShift);
                        };
                        

                        return (
                            <div className="d-flex align-items-center gap-2">
                            {/* Dropdown for Shift Names */}
                            <Dropdown
                                value={shifts.find((shift) => shift.code === code && shift.name === name)}
                                options={shifts}
                                optionLabel="name"
                                placeholder={SELECT_SHIFT}
                                onChange={handleDropdownChange}
                                className="w-50"
                                style={shiftStyle}
                                itemTemplate={(option) => (
                                <div>
                                    {option.name}
                                </div>
                                )}
                                filter
                            />

                            {/* Time Pickers for Start and End Time */}
                            <MlxTimePicker
                                value={startTime ? dayjs(startTime, 'HH:mm:ss') : null}
                                onChange={(time) => handleTimeChange(name, time, 'startTime')}
                                disabled={code !== 'Custom Shift'}
                                style={{ ...shiftStyle, width: '120px' }}
                                className="form-control antD-shiftcard"
                            />
                            <MlxTimePicker
                                value={endTime ? dayjs(endTime, 'HH:mm:ss') : null}
                                onChange={(time) => handleTimeChange(name, time, 'endTime')}
                                disabled={code !== 'Custom Shift'}
                                style={{ ...shiftStyle, width: '120px' }}
                                className="form-control antD-shiftcard"
                            />
                            </div>
                        );
                        }}
                    </Field>
                </div>

                {/* ADD BUTTON */}
                <div className="flex-grow-0">{shiftIndex === 0 && AddShiftButton({ arrayHelpers: arrayHelpers })}</div>

                {/* REMOVE BUTTON */}
                {shiftIndex > 0 && (
                    <i className="ri-delete-bin-6-line delete mt-4" onClick={() => arrayHelpers.remove(shiftIndex)}></i>
                )}
                <ErrorMessage
                    className="formik-error visible w-100"
                    name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.code`}
                    component="div"
                />

                <ErrorMessage
                    className="formik-error visible w-100"
                    name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.endTime`}
                    component="div"
                />
            </div>
            <div className=" mb-3 pb-2 border-bottom-dashed">
                <div className="d-flex align-items-center">
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.close`}>
                        {({ field, form }: FieldProps) => {
                            return (
                                <>
                                    <InputSwitch
                                        checked={field.value}
                                        onChange={(e: InputSwitchChangeEvent) => {
                                            const { value } = e
                                            form.setFieldValue(field.name, value)
                                        }}
                                    />
                                    <label className="me-3 ms-1">{CLOSE}</label>
                                </>
                            )
                        }}
                    </Field>
                    <ErrorMessage
                        className="formik-error"
                        name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.close`}
                        component="div"
                    />
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.bd`}>
                        {({ field, form }: FieldProps) => {
                            return (
                                <>
                                    <InputSwitch
                                        checked={field.value}
                                        onChange={(e: InputSwitchChangeEvent) => {
                                            const { value } = e
                                            form.setFieldValue(field.name, value)
                                        }}
                                    />
                                    <label className="ms-1">{BD}</label>
                                </>
                            )
                        }}
                    </Field>
                </div>

                <div className="d-block mt-2">
                    <ErrorMessage
                        className="formik-error"
                        name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.bd`}
                        component="div"
                    />
                </div>

            </div>

        </>
    )
}

export default ShiftAssignmentCard
