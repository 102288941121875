import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { addGlobalStaffingApi, days, initialDataMinimumEmployeeCount, JobRoleWithMinCount, updateRolesListWithStaffCount, weeklyEmployeeCountValidationSchema } from '../../helpers/workTimeScheduleHelper'
import { AddGlobalStaffingCount, JobRole, MinimumEmployeeCountType, PerDayCountForListing, ToastMessageProps } from '../../constants/staticTypes'
import { AxiosResponse } from 'axios'
import { Badge } from 'primereact/badge'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from 'formik'
import { getUserRoles } from '../../helpers/userRolesHelper'
import { InputNumber } from 'primereact/inputnumber'
import { JTranslation } from '../../helpers/jTranslate'
import { SAVE, CANCEL, STAFF_COUNT, MINIMUM_STAFF_COUNT } from '../../constants/strings'
import { STAFFING_REQUIREMENTS_LIST, USER_ROLES } from '../../constants/queryKeys'
import { useQueryClient } from 'react-query'
import Offcanvas from 'react-bootstrap/Offcanvas'
import StaffCountCard from './StaffCountCard'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'

type Props = {
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
}

function MinimumEmployeeCountSideBar({ setToastMessage }: Props) {
	const queryClient = useQueryClient()
	const globalStaffingMutation = useMutationHook(queryClient, true)
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)
	const [staffingRequirement, setStaffingRequirement] = useState<MinimumEmployeeCountType>(initialDataMinimumEmployeeCount)
	const [rolesList, setRolesList] = useState<JobRole[]>([]);
	const [rolesListWithMinStaff, setRolesListWithMinStaff] = useState<JobRoleWithMinCount[]>([]);

	useEffect(() => {
		if (rolesList.length) {
			const listWithMinCount = updateRolesListWithStaffCount({ rolesListWithMinStaff, staffingRequirement });
			setStaffingRequirement(listWithMinCount)
		}
	}, [rolesList, rolesListWithMinStaff]); // eslint-disable-line

	// roles list
	useQueryHook(USER_ROLES, getUserRoles, (res) => {
		let filteredData = res.data?.data.lists.filter((item: any) => item.isAvailableForSchedule)

		const sortedRoles = filteredData.sort((a: any, b: any) =>
			a.jobRoleName.localeCompare(b.jobRoleName)
		);

		setRolesList(sortedRoles);
		setRolesListWithMinStaff(sortedRoles);
	});

	const closeSidebar = () => setShowSideBar(false)

	const onSuccessOfGlobalStaffing = (res: AxiosResponse<any, any>) => {
		const staffingData: PerDayCountForListing[] = res.data.data.lists
		// Sort data to match the order of the pascalCaseDays array
		const sortedData = days.map(dayName => staffingData.find(weekDay => weekDay.dayName.toLowerCase() === dayName));
		setStaffingRequirement(sortedData as PerDayCountForListing[])
	}

	// on create custom menu success
	const onSuccess = (message: string, variant: string) => {
		const refetchQueries = queryClient.refetchQueries([STAFFING_REQUIREMENTS_LIST])
		refetchQueries.then(() => {
			const res = queryClient.getQueryData(STAFFING_REQUIREMENTS_LIST) as AxiosResponse<any, any>;
			if (res) onSuccessOfGlobalStaffing(res)
		})
		setToastMessage({ message, variant, show: true })
	}

	// on api error
	const onError = (message: string, variant: string) => {
		queryClient.refetchQueries([STAFFING_REQUIREMENTS_LIST])
		setToastMessage({ message, variant, show: true })
	}

	const sanitizeFormData = (formData: MinimumEmployeeCountType) => {
		return formData.map((weekday) => {
			const { jobRoles } = weekday;
			const cleanedUpRoles = jobRoles.map((jobRole) => {
				const { id, minCount } = jobRole;
				return { id, minCount };
			});

			return { ...weekday, jobRoles: cleanedUpRoles };
		});
	};

	const putStaffingRequirementsApiCall = (formData: MinimumEmployeeCountType) => {
		const sanitizedFormData = sanitizeFormData(formData)

		const globalStaffingData: AddGlobalStaffingCount = {
			data: sanitizedFormData,
		}
		// API call
		addGlobalStaffingApi(globalStaffingMutation, globalStaffingData, onSuccess, onError)
	}

	useEffect(() => {
		const res = queryClient.getQueryData(STAFFING_REQUIREMENTS_LIST) as AxiosResponse<any, any>;
		if (res) onSuccessOfGlobalStaffing(res)
	}, [queryClient])

	return (
		<Offcanvas
			show={showSideBar}
			onHide={() => closeSidebar()}
			backdrop="static"
			// responsive="xl"
			placement="end"
			className="custom-offcanvas minimum-employee-sidebar"
		>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>
					<JTranslation text={STAFF_COUNT} />
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Formik
					initialValues={staffingRequirement}
					validationSchema={weeklyEmployeeCountValidationSchema}
					enableReinitialize={true}
					onSubmit={putStaffingRequirementsApiCall}
				>
					{({ errors, touched }) => (
						<Form>
							<div className="row">
								<div className="col-md-12 col-lg-12 mb-3">
									<FieldArray
										name="data"
										render={() => (
											<Accordion className='staffCountCustom'>
												{staffingRequirement.map((weekDay, index) => (
													<AccordionTab
														header={
															<span className="flex align-items-center gap-2 w-full d-flex justify-content-between">
																<span className="font-bold white-space-nowrap">
																	<JTranslation typeCase="pascal" text={weekDay.dayName} />
																</span>
																<span>
																	<Badge value={Number(weekDay.minCount)} severity="secondary" className="ml-auto" />
																</span>
															</span>
														}
													>
														<div className="row my-3">
															<div className="col d-flex align-items-center">
																<label className='fw-bold'>{MINIMUM_STAFF_COUNT}</label>
															</div>
															<div className="col">
																<Field
																	name={`[${index}].minCount`}
																>
																	{({ field, form }: FieldProps) => (
																		<InputNumber
																			id={`[${index}].minCount`}
																			data-testid={`[${index}].minCount`}
																			value={Number(field.value)}
																			className='input-number-custom'
																			onChange={(event) => form.setFieldValue(field.name, event.value, true)}
																			style={(errors[index] && errors[index].minCount) && (touched[index] && touched[index].minCount)
																				? { border: '1px solid red' }
																				: undefined
																			}
																			showButtons={true}
																			useGrouping={false}
																			min={0} max={100}
																		/>
																	)}
																</Field>
																<ErrorMessage
																	className="formik-error"
																	name={`[${index}].minCount`}
																	component="div"
																	render={(error) => (
																		<span className="formik-error">
																			<JTranslation text={error} />
																		</span>
																	)}
																/>
															</div>
														</div>
														<hr />
														<StaffCountCard
															errors={errors}
															touched={touched}
															weekDayIndex={index}
															weekDay={weekDay}
														/>
													</AccordionTab>
												))}
											</Accordion>
										)}
									/>
								</div>
							</div>
							<div></div>
							<div className="save-btn-section shadow save-btn-absolute">
								<button
									className="btn btn-custom-primary-outline"
									type="reset"
									data-testid="cancel-btn"
									onClick={() => setShowSideBar(false)}
								>
									<JTranslation typeCase="pascal" text={CANCEL} />
								</button>

								<button className="btn btn-custom-primary" type="submit" data-testid="save-btn">
									<JTranslation typeCase="pascal" text={SAVE} />
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default MinimumEmployeeCountSideBar
