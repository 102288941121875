import * as Yup from 'yup'
import HttpServiceHelper from './httpServiceHelper'
import { AlertVariant, HttpMethods } from '../constants/constants'
import {
	AddAvailabilityFormData,
	AddAvailabilityResponse,
	AddUpdateMandatoryResponse,
	AvailabilityFormData,
	AvailabilityTimeFormData,
	MandatoryFormData,
	AvailabilityWeekFormData,
	Payload, SingleAvailabilityFormData,
	UpdateAvailabilityFormData,
	OffDaysParams
} from '../constants/staticTypes'
import dayjs, { Dayjs } from 'dayjs'
import { getAllWeekDays, getErrorMessage } from './utils'
import { UseMutationResult } from 'react-query'
import { AxiosResponse } from 'axios'
import { availabilityForManager, mandatoryDayApi, staffAvailability } from '../constants/apiEndPoints'

export type AvailabilityRequestStatus = {
	id: string
	newStatus: 'PENDING' | 'APPROVED' | 'REJECTED'
	note: string
}

export type AvailabilityConfirmPopupType = {
	visible: boolean
	buttonName: string
	requestId: string
	action: 'PENDING' | 'APPROVED' | 'REJECTED'
}

export const initialValuesForConfirmPopup: AvailabilityConfirmPopupType = {
	visible: false,
	buttonName: '',
	requestId: '',
	action: 'PENDING',
}

export const currentWeekDays = getAllWeekDays(dayjs())

export const currentWeekDaysString = (format = 'YYYY-MM-DD') => {
	return currentWeekDays.map((day: Dayjs) => day.format(format))
}

export const initialValuesMandatoryDay: MandatoryFormData = {
	id: "",
	startDate: dayjs().startOf('day').toDate(),
	endDate: dayjs().endOf('day').toDate(),
	durationType: 'full-day',
	timeLabel: 'morning',
}

export const mandatoryHalfDayValidation = Yup.object({
	startDate: Yup.date().required('From date is required'),
	durationType: Yup.string().required(),
	timeLabel: Yup.string().required('This is a required field'),
})

export const mandatoryFullDayValidation = Yup.object({
	durationType: Yup.string().required(),
	startDate: Yup.date()
		.required('From date is required')
		.test('date-range', 'From date must be less than or equal to To date', function (startDate, { parent }) {
			return dayjs(startDate).isBefore(parent.endDate, 'day') || dayjs(startDate).isSame(parent.endDate, 'day')
		}),
	endDate: Yup.date()
		.required('To date is required')
		.test('date-range', 'To date must be greater than or equal to From date', function (endDate, { parent }) {
			return dayjs(endDate).isAfter(parent.startDate, 'day') || dayjs(endDate).isSame(parent.startDate, 'day')
		}),
})

export const initialValuesForAvailability: Readonly<AvailabilityFormData> = {
	staffId: '',
	startDate: currentWeekDays[0],
	endDate: currentWeekDays[6],
	isRecurring: 'recurring',
	weekdays: currentWeekDays.map((day: Dayjs) => {
		const availabilityWeekData: Readonly<AvailabilityWeekFormData> = {
			weekday: day,
			isAllDay: true,
			isAvailable: true,
			availableTimes: [],
			notes: '',
		}
		return availabilityWeekData
	}),
}

export const availabilitySchema = Yup.object({
	startDate: Yup.string().required('Start date is required'),
	weekdays: Yup.array().of(
		Yup.object().shape({
			weekday: Yup.string().required('Weekday is required'),
			isAllDay: Yup.boolean(),
			isAvailable: Yup.boolean(),
			availableTimes: Yup.array().test({
				test: function (value, context) {
					const isAllDay = context.parent?.isAllDay
					const isAvailable = context.parent?.isAvailable

					if (!isAllDay && isAvailable) {
						return value && value.length > 0
					}

					return true
				},
				message: 'Please add at-least one available time',
			}),
		})
	),
})

export const isTimeRangeConflict = (newRange: AvailabilityTimeFormData, existingRanges: AvailabilityTimeFormData[]) => {
	for (const existingRange of existingRanges) {
		// Check if the new range overlaps with any existing range
		if (
			((newRange.from.isSame(existingRange.from) || newRange.from.isAfter(existingRange.from)) &&
				newRange.from.isBefore(existingRange.to)) ||
			(newRange.to.isAfter(existingRange.from) &&
				(newRange.to.isSame(existingRange.to) || newRange.to.isBefore(existingRange.to))) ||
			(newRange.from.isBefore(existingRange.from) && newRange.to.isAfter(existingRange.to))
		) {
			return true // Conflict found
		}
	}
	return false // No conflict found
}

// fetch user's availability list
export const getAvailabilityOfUsers = ({ staffId, startDate }: { staffId: string; startDate: string }) => {
	return HttpServiceHelper({
		url: `${staffAvailability}/get`,
		method: HttpMethods.POST,
		data: {
			staffId,
			startDate,
		},
	})
}

// fetch all user's availability list
export const getAllAvailabilityOfUsers = (urlParams: string = '') => {
	return HttpServiceHelper({
		url: `${availabilityForManager}/manage/get${urlParams}`,
		method: HttpMethods.GET,
	})
}

export const addStaffAvailability = (
	availabilityMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: AddAvailabilityFormData,
	onSuccess: (data: AddAvailabilityResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	availabilityMutation.mutate(
		{
			url: `${staffAvailability}/manage`,
			method: HttpMethods.POST,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const updateStaffAvailability = (
	availabilityMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: UpdateAvailabilityFormData,
	onSuccess: (data: AddAvailabilityResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	availabilityMutation.mutate(
		{
			url: `${staffAvailability}/manage`,
			method: HttpMethods.PUT,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const deleteStaffAvailability = (
	availabilityMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: SingleAvailabilityFormData,
	onSuccess: (data: AddAvailabilityResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	availabilityMutation.mutate(
		{
			url: `${staffAvailability}/manage`,
			method: HttpMethods.DELETE,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const updateAvailabilityStatus = (
	availabilityMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: AvailabilityRequestStatus,
	onSuccess: (data: AddAvailabilityResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	availabilityMutation.mutate(
		{
			url: `${availabilityForManager}/manage/status`,
			method: HttpMethods.POST,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const getAllMandatoryDays = () => {
	return HttpServiceHelper({
		url: `${mandatoryDayApi}/manager/mandatory`,
		method: HttpMethods.GET,
	})
}

export const addMandatoryDay = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: MandatoryFormData,
	onSuccess: (data: AddUpdateMandatoryResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${mandatoryDayApi}/manager/mandatory`,
			method: HttpMethods.POST,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const updateMandatoryDay = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: MandatoryFormData,
	onSuccess: (data: AddUpdateMandatoryResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${mandatoryDayApi}/manager/mandatory`,
			method: HttpMethods.PUT,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const deleteMandatoryDay = (
	timeOffMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	params: { id: string },
	onSuccess: (data: AddUpdateMandatoryResponse) => void,
	onError: (res: string, variant: string) => void
) => {
	timeOffMutation.mutate(
		{
			url: `${mandatoryDayApi}/manager/mandatory`,
			method: HttpMethods.DELETE,
			data: params,
		},
		{
			onSuccess: (res) => onSuccess(res.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
export const getOffDays = (params: OffDaysParams) => {
	return HttpServiceHelper({
		url: `${staffAvailability}/manage/off_days/get`,
		method: HttpMethods.POST,
		data: params,
	})
}


// fetch user's availability list
export const getAvailabilityGlance = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
	return HttpServiceHelper({
		url: `${staffAvailability}/manager/manage/glance`,
		method: HttpMethods.POST,
		data: {
			endDate,
			startDate,
		},
	})
}
