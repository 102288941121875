import { Avatar } from "antd";

interface UserAvatarProps {
  name: string;
  imageUrl: string | null;
}

// Function to generate a consistent hex color from a string
const generateColorFromName = (name: string): string => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `#${((hash & 0x00ffffff) | 0x808080).toString(16).padStart(6, "0")}`;
};

// Function to determine text color (black or white) based on background brightness
const getContrastColor = (hex: string): string => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black for bright backgrounds, white for dark backgrounds
  return luminance > 0.6 ? "#000" : "#fff";
};

const UserAvatar = ({ name, imageUrl }: UserAvatarProps) => {
  const backgroundColor = generateColorFromName(name);
  const textColor = getContrastColor(backgroundColor);
  const firstLetter = name.charAt(0).toUpperCase();

  return (
    <Avatar
      src={imageUrl || undefined}
      className="schedule-avatar"
      style={{
        backgroundColor: imageUrl ? "transparent" : backgroundColor,
        color: imageUrl ? "inherit" : textColor,
        fontWeight: "bold",
      }}
    // size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 50 }}
    >
      {!imageUrl && firstLetter}
    </Avatar>
  );
};

export default UserAvatar;
