import { Field, ErrorMessage, FormikErrors, FormikTouched, FieldProps } from 'formik';
import { JTranslation } from '../../helpers/jTranslate';
import { MinimumEmployeeCountType, PerDayCountForListing } from '../../constants/staticTypes';
import { InputNumber } from 'primereact/inputnumber';

type Props = {
    errors: FormikErrors<MinimumEmployeeCountType>;
    touched: FormikTouched<MinimumEmployeeCountType>;
    weekDayIndex: number;
    weekDay: PerDayCountForListing
};

const StaffCountCard = ({ weekDayIndex, errors, touched, weekDay }: Props) => {
    return (
        <>
            {weekDay.jobRoles.map((role, index) => (
                <div className="my-3 row" key={role.id}>
                    <label className='col'>{role.jobRoleName}</label>
                    <Field
                        className='col'
                        name={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                    >
                        {({ field, form }: FieldProps) => (
                            <InputNumber
                                id={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                data-testid={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                value={Number(field.value)}
                                className='input-number-custom'
                                onChange={(event) => form.setFieldValue(field.name, event.value, true)}
                                style={
                                    errors &&
                                        (errors[weekDayIndex] as any)?.jobRoles?.[index]?.minCount &&
                                        touched && // @ts-ignore
                                        (touched[weekDayIndex]?.jobRoles?.[index]?.minCount)
                                        ? { border: '1px solid red' }
                                        : undefined
                                }
                                showButtons={true}
                                useGrouping={false}
                                min={0} max={999}
                            />
                        )}
                    </Field>
                    <ErrorMessage
                        className="formik-error"
                        name={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                        component="div"
                        render={(error) => (
                            <span className="formik-error">
                                <JTranslation text={error} />
                            </span>
                        )}
                    />
                </div>
            ))}
        </>
    );
};

export default StaffCountCard;
