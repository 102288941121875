import { Field, ErrorMessage, FieldProps } from 'formik';
import { JTranslation } from '../../helpers/jTranslate';
import { InputNumber } from 'primereact/inputnumber';
import { StaffRequirement } from '../../helpers/workTimeScheduleHelper';

type Props = {
    staffingRequirement: StaffRequirement
};

const StaffCountCardForEvents = ({  staffingRequirement }: Props) => {

    return (
        <>
            {staffingRequirement.jobRoles.map((role, index) => (
                <div className="mb-2 row" key={role.id}>
                    <label className='col'>{role.jobRoleName}</label>
                    <Field
                        className='col'
                        name={`jobRoles.[${index}].minCount`}
                    >
                        {({ field, form }: FieldProps) => (
                            <InputNumber
                                id={`jobRoles.[${index}].minCount`}
                                data-testid={`jobRoles.[${index}].minCount`}
                                value={field.value}
                                className="input-number-custom p-inputtext-sm"
                                onChange={(event) => form.setFieldValue(field.name, event.value, true)}
                                showButtons={true}
                                useGrouping={false}
                                min={0} max={999}
                            />
                        )}
                    </Field>
                    <ErrorMessage
                        className="formik-error"
                        name={`jobRoles.[${index}].minCount`}
                        component="div"
                        render={(error) => (
                            <span className="formik-error">
                                <JTranslation text={error} />
                            </span>
                        )}
                    />
                </div>
            ))}
        </>
    );
};

export default StaffCountCardForEvents;
